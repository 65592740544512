<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar :color="cor.active.b">
        <v-btn class="ms-1 me-0 pe-0 ps-0" icon small v-bg:S#3 @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-600 fs-11pt">
          Quebrar conteúdos
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-card class="m-0 my-2 p-1 px-2 text-justify" v-if="uc != null">
          <p class="m-0 mt-1 p-0 fs-10pt f-sanspro fw-600 line-height-3">Meta de compreensão:</p>
          <readmore class="fs-10pt mt-0 f-raleway fw-400" size="200" :text="uc.ementa.texto" />
        </v-card>
        <v-card class="p-1">
          <p class="m-0 mx-1 mb-1 f-lato fs-10pt fw-300">
            Indique o caractere separador de quebra:
          </p>
          <v-container fluid class="m-0 p-0 border">
            <v-row no-gutters align="center" justify="space-around" class="m-0 p-0">
              <v-col cols="auto" class="m-0 p-0">
                <v-checkbox class="m-0 p-0" v-model="separador" label=";" value=";" hide-details></v-checkbox>
              </v-col>
              <v-col cols="auto" class="m-0 p-0">
                <v-checkbox class="m-0 p-0" v-model="separador" label="." value="." hide-details></v-checkbox>
              </v-col>
              <v-col cols="auto" class="m-0 p-0">
                <v-container fluid class="m-0 p-0">
                  <v-row no-gutters align="center">
                    <v-col cols="1">
                      <v-checkbox class="m-0 p-0" v-model="separador" label="" value="outro" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="11" class="h26">
                      <v-text-field :disabled="separador.indexOf('outro')==-1" class="p-0 m-0 ps-4" dense solo hide-details>
                        <template v-slot:label>
                          <span v-model="outro" class="fs-10pt f-lato fw-500 line-height-0">outro</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "",
  components: {
    'readmore': () => import("@/components/readmore.vue"),

  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      dialog: false,
      separador: [ ";" ],
      outro: "",
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    show() {
      this.dialog = true;
    }
  }
}
</script>

<style>

.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  padding-left: 4px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>
